
import * as uploadcareRuntime$hFzdg9jhhX from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/uploadcare'
import * as weservRuntime$h9VDLdobGI from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/weserv'
import * as netlifyRuntime$2xGLoGD2LP from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 768,
    "xl": 768,
    "xxl": 768,
    "2xl": 768
  },
  "presets": {},
  "provider": "netlify",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['uploadcare']: { provider: uploadcareRuntime$hFzdg9jhhX, defaults: {"provider":"uploadcare"} },
  ['weserv']: { provider: weservRuntime$h9VDLdobGI, defaults: {"provider":"weserv","baseURL":"https://uploads.ilovefreegle.org","weservURL":"https://delivery.ilovefreegle.org"} },
  ['netlify']: { provider: netlifyRuntime$2xGLoGD2LP, defaults: {} }
}
        